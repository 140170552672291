<template>
  <div class="popup-mask" @click="closePopup">
    <div class="popup-wrapper">
      <div class="container popup-container" @click.stop>
        <div class="modal-header text-center">
          <h5 class="popup-title">Zaktualizuj sesję czytania</h5>
        </div>
        <div class="input-container">
          <input type="number" class="form-input w-100" placeholder="Na której stronie skończyłeś czytać?" v-model="pagesAmount">
        </div>
        <div class="input-container">
          <label class="label-small d-block text-center" for=""><strong>jak długo mniej więcej czytałeś?</strong></label>
        </div>
        <div class="form-checkbox">
          <input type="checkbox" name="checkbox_1" id="checkbox_1" v-model="isChecked">
          <label for="checkbox_1">
            skończyłem czytać tę książkę
          </label>
        </div>
        <div class="input-spinner mt-4">
          <div class="top-heading-small text-center">Jak długo mniej więcej czytałeś?</div>
          <div class="input-spinners normal ">
            <div class="input-spinner-wrapper input-spinner-time">
              <input type="hidden" name="input-minute">
                <VerticalSlider :options="sessionHoursArray" :time="readingHours" @active-index="setSessionHours"/>
              <div class="input-spinner-label">h</div>
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
              <VerticalSlider :options="sessionMinutesArray" :time="readingMinutes" @active-index="setSessionMinutes"/>
                <div class="input-spinner-label">min.</div>
            </div>
          </div>
        </div>
        <button class="btn-black-full w-100 mt-3 mb-1" @click="saveSession">Zaktualizuj</button>
        <button class="btn-gray-border w-100 mt-1 mb-1" @click="closePopup">Anuluj</button>
      </div>
    </div>
  </div>
</template>
<script>
import VerticalSlider from './VerticalSlider'
export default {
  name: 'ModalUpdateSession',
  components: {
    VerticalSlider
  },
  props: {
    index: Number,
    minutes: Array,
    books: Object,
    planId: String,
    time: Number
  },
  data () {
    return {
      title: 'Zaktualizuj sesję czytania',
      pagesAmount: '',
      isChecked: false,
      sessionHoursArray: ['00', '01', '02', '03', '04', '05', '06', '07'],
      sessionMinutesArray: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
      activeSessionHours: '',
      activeSessionMinutes: '',
      readingMinutes: '',
      readingHours: ''
    }
  },
  methods: {
    saveSession () {
      const sessionHours = this.activeSessionHours === '' ? 0 : parseInt(this.activeSessionHours.substring(1))
      const sessionMin = this.activeSessionMinutes === '' ? 0 : parseInt(this.activeSessionMinutes)
      const readingTime = (sessionHours * 60) + sessionMin
      const today = new Date()
      const formattedDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.pagesAmount = this.pagesAmount === '' ? 0 : this.pagesAmount
      this.isChecked = this.isChecked === true ? 1 : 0
      const getData = `date=${formattedDate}&time=${readingTime}&readed=${this.isChecked}&pages_readed=${this.pagesAmount}`
      this.$https('plan/' + this.planId + '/books/' + this.books.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closePopup()
      })
        .catch(error => {
          console.log('', error)
        })
    },
    setSessionHours (index) {
      this.activeSessionHours = this.sessionHoursArray[index]
    },
    setSessionMinutes (index) {
      this.activeSessionMinutes = this.sessionMinutesArray[index]
    },
    closePopup () {
      const popupStatus = false
      this.$emit('close-popup', popupStatus)
    }
  },
  watch: {
    isChecked: function () {
      if (this.isChecked === true) {
        this.pagesAmount = this.books.objetosc
      } else {
        this.pagesAmount = ''
      }
    }
  },
  created () {
    this.readingMinutes = this.time % 60
    this.readingHours = Math.trunc(this.time / 60)
    // this.readingMinutes = this.minutes[this.index] % 60
    // this.readingHours = Math.trunc(this.minutes[this.index] / 60)
  }
}
</script>
<style lang="scss" scoped>

</style>
