<template>
<div>
    <swiper class="swiper" ref="mySwiper" :options="swiperOptions" @slideChange="sendData" @ready="run">
    <div class="growing-slide swiper-slide slide-wrapper" v-for="(book, key) in props" :key="key">
      <swiper-slide>
          <!-- <div class="growing-slide-cover-container books-featured-slide-cover-container">
            <div class="growing-slide-cover" :class="{'slide-play-sign selected': isActive[key] === false}"
            @click.prevent="toggleTimer(key)">
                <div :class="{'cover checked-cover': isActive[key] === true}">
                  <img
                    :src="(coverUrl + book.image)"
                    class="slide-cover-image"
                    :alt="book.category"
                    @click.stop />
                </div>
              <div class="timer-info"
              v-show="isActive[key] === true"
              @click.stop>
                <div>
                  <a class="play-control-playpause" v-if="!isStoped" @click="stopTimer(key)">
                    <i class="fas fa-pause"></i>
                  </a>
                  <a class="play-control-playpause" v-else @click="startTimer(key)">
                    <i class="fas fa-play"></i>
                  </a>
                </div>
                <div class="w-50">
                  <span class="play-control-progress"
                  >
                    <span v-if="minutes[key] < 10">0</span>{{minutes[key]}}
                    :
                    <span v-if="seconds[key] < 10">0</span>{{seconds[key]}}
                  </span>
                </div>
                <div>
                  <a class="play-control-stop" @click="openPopup(book); stopTimer(key)">
                    <i class="fas fa-stop"></i>
                  </a>
                </div>
              </div>
            </div>
          </div> -->
         <div class="growing-slide-cover-container books-featured-slide-cover-container">
            <div class="growing-slide-cover" :class="{'slide-play-sign selected': isActive[key] === false}">
                <div :class="{'cover checked-cover': isActive[key] === true}">
                  <img
                    :src="(coverUrl + book.image)"
                    class="slide-cover-image"
                    :alt="book.category"
                    @click.stop />
                </div>
              <div class="timer-info"
              v-show="isActive[key] === true"
              @click.stop>
                <div>
                  <a class="play-control-playpause" v-if="!isStoped" @click="stopTimer(key)">
                    <i class="fas fa-pause"></i>
                  </a>
                  <a class="play-control-playpause" v-else @click="startTimer(key)">
                    <i class="fas fa-play"></i>
                  </a>
                </div>
                <div class="w-50">
                  <span class="play-control-progress"
                  >
                    <span v-if="minutes[key] < 10">0</span>{{minutes[key]}}
                    :
                    <span v-if="seconds[key] < 10">0</span>{{seconds[key]}}
                  </span>
                </div>
                <div>
                  <a class="play-control-stop" @click="openPopup(book, minutes[key]); stopTimer(key)">
                    <i class="fas fa-stop"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button class="play-stopwatch-btn"
            v-if="isActive[key] === false"
            @click.prevent="toggleTimer(key)">
            <i class="play-btn fas fa-play"></i>
          </button>
          <span class="refresh">{{refresh}}</span>
      </swiper-slide>
    </div>
  </swiper>
      <ModalUpdateSession v-if="isPopupShown"
      @close-popup="closePopup"
      :index='index'
      :minutes='minutes'
      :books="bookInfo"
      :planId="planId"
      :time="setTime"
      />
</div>
</template>
<script>
import EventBus from '../eventBus'
import ModalUpdateSession from '../components/ModalUpdateSession'
export default {
  name: 'TrainingPlanStopwatch',
  components: {
    ModalUpdateSession
  },
  props: {
    props: Array,
    planId: String
  },
  data () {
    return {
      isUpdate: false,
      isActive: [],
      bookInfo: [],
      control: true,
      isStoped: false,
      minutes: [],
      seconds: [],
      setTime: 0,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      },
      index: 0,
      isReading: true,
      stopwatch: [],
      refresh: 0,
      refreshData: '',
      isPopupShown: false
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    this.swiper.slideTo(0, 500, true)
  },
  methods: {
    openPopup (info, minutes) {
      this.bookInfo = info
      this.setTime = minutes
      this.isPopupShown = true
    },
    closePopup (status) {
      this.isPopupShown = status
      this.$emit('update')
    },
    run () {
      this.sendData()
      this.getSlides()
    },
    refreshFunc () {
      this.refresh++
    },
    sendData () {
      this.slideIndex = this.$refs.mySwiper.$swiper.activeIndex
      var el = this.props[this.slideIndex]
      let rat = parseFloat(el.rating, 10)
      rat = rat.toFixed(1)
      const payload = {
        autor: el.autor,
        tytul: el.tytul,
        autor_id: el.author_id,
        id: el.book_id,
        rating: parseFloat(rat, 10),
        liczba_stron: parseInt(el.objetosc),
        przeczytane_strony: parseInt(el.pages_readed)
      }

      EventBus.$emit('training-plan-books', payload)
    },
    toggleTimer (key) {
      this.isActive[key] = true
      this.control = !this.control
      if (this.isActive[key] === true) {
        this.startTimer(key)
      } else {
        this.stopTimer(key)
      }
    },
    startTimer (key) {
      this.isStoped = false
      this.stopwatch[key] = setInterval(() => {
        this.countTime(key)
        this.refreshData = this.refreshFunc()
      }, 1000)
    },
    restartTimer (key) {
      this.isStoped = false
      this.startTimer(key)
    },
    stopTimer (key) {
      this.isStoped = true
      clearInterval(this.stopwatch[key])
      clearInterval(this.refreshData)
      this.isActive[key] = false
      this.control = !this.control
    },
    countTime (key) {
      this.seconds[key]++
      if (this.seconds[key] > 59) {
        this.seconds[key] = 0
        this.minutes[key]++
      }
    },
    getSlides () {
      for (let i = 0; i < this.props.length; i++) {
        this.isActive.push(false)
        this.minutes.push(0)
        this.seconds.push(0)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .timer-info {
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 100;
    color: #fff;
    padding: 20px 10px 10px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
      a {
        color: #fff;
      }
  }
  .play-controls {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    color: #fff;
    padding: 20px 10px 10px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }

  .refresh {
    display: none;
  }
.swiper-slide-active {
  .play-control-progress {
    box-sizing: border-box;
    border: 1px solid #fff;
    padding: 20px 10px;
    border-radius: 50%;
  }
}

.swiper-slide-prev, .swiper-slide-next {
  .play-control-progress {
    box-sizing: border-box;
    border: none;
    padding: 20px 10px;
    border-radius: 50%;
    font-size: 10px;
  }
}

.cover {
  max-height: 100%;
  width: 100%;
  display: block;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 50;
  }

  &.checked {
    &-cover {
      &::before {
        display: block;
        content: "";
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
.play-control-stop, .play-control-playpause {
  border: 1px solid #fff;
  padding: 8px 10px;
  border-radius: 50%;
}

.growing-cover-slider .growing-slide .growing-slide-cover.slide-play-sign::after {
  content: '';
}
.growing-cover-slider .growing-slide .growing-slide-cover.slide-play-sign.selected::before, .growing-cover-slider .growing-slide .growing-slide-cover.slide-play-sign.selected::after {
  opacity: 0;
}
.play-stopwatch-btn {
  position: absolute;
  left: 45%;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 28px;
  height: 28px;
  font-size: 14px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  padding-left: -5px;
}
</style>
