<template>
  <div>
    <TopMenuSubheadline :title="title" />
    <div class="app-content-box bg-gray">
      <div class="wrapper mt-5 mb-5" v-if="isLoaded && isLoaded2">
        <div class="container">
        <div class="mt-4 d-flex justify-content-center w-100">
          <div class="trainings-links">
            <div  class="links-heading"><b><u>aktualny</u></b></div>
            <div class="links-heading">
              <router-link class="text-dark" :to="{name: 'TrainingPlanHistory'}">historia</router-link>
            </div>
          </div>
        </div>
          <div class="text-center top-heading-small mt-3" v-if="createdPlan.type === 'books'">
            Plan książkowy
          </div>
          <div class="text-center top-heading-small mt-3" v-if="createdPlan.type === 'time'">
            Plan czasu
          </div>
           <div class="text-center top-heading-small mt-3" v-if="createdPlan.type === 'pages'">
            Plan stronicowy
          </div>
          <div class="text-center book-heading-big">
           {{createdPlan.name}}
          </div>
          <div class="text-center mt-3">
           <span @click="openModal"><i class="fas fa-ellipsis-h"></i></span>
          </div>
          <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal" noclose="true">
            <div class="text-center mb-2 modal-main-title">Czy na pewno chcesz usunąć plan?</div>
            <button class="btn-black-border w-100 mt-3 mb-1" @click="deletePlan">Usuń</button>
            <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Anuluj</button>
          </ModalSlot>
          <ModalSlot title="Dodaj książkę" v-if="popupSuccess">
            <div class="text-center">Plan czytelniczy zakończył się sukcesem!</div>
            <router-link :to="{name: 'TrainingPlanHistory'}"><button class="btn-black-border w-100 mt-3 mb-1" >Zobacz podsumowanie</button></router-link>
            <router-link to="/wybierz-plan"><button class="btn-black-full w-100 mt-3 mb-1">Załóż nowy plan</button></router-link>
            <router-link to="/"><button class="btn-black-border w-100 mt-3 mb-1" >Zakończ</button></router-link>
          </ModalSlot>
          <div>
            <div class="status-icon-row-summary row mt-4">
              <div class="col-4 pr-0">
                <div class="d-inline-block text-left">
                <div class="icon-big mb-0">
                    <i class="far fa-calendar-alt"></i>
                </div>
                <br> sesje <br>
                <span v-if="createdPlan.session === 'day'">codzienne</span>
                <span v-if="createdPlan.session === 'week'">tygodniowe</span>
                </div>
              </div>
              <div class="col-4 pl-0 pr-0 text-center">
                <div class="d-inline-block text-left">
                  <div class="icon-big mb-0">
                    <i class="fas fa-book"></i>
                  </div>
                  <br>
                  <span v-if="createdPlan.type === 'time'">założenie <br> {{createdPlan.time_per}} stron</span>
                    <span v-if="createdPlan.type === 'books'">
                      założenie <br> {{createdPlan.books_total}}
                      <span v-if="createdPlan.books_total === 1">książka</span>
                      <span v-if="createdPlan.books_total >= 5">książek</span>
                      <span v-else>książki</span>
                    </span>
                    <span v-if="createdPlan.type === 'pages'">założenie <br> {{createdPlan.pages_per}} stron</span>
                </div>
              </div>
              <div class="col-4 pl-0 text-right">
                <div class="d-inline-block text-left">
                <div class="icon-big mb-0">
                  <i class="far fa-clock"></i>
                </div>
                  <br>
                  czas trwania <br> {{createdPlan.full_time}}
                  <span v-if="createdPlan.full_time_unit === 'month' && createdPlan.full_time > 1 && createdPlan.full_time < 5">miesiące</span>
                  <span v-if="createdPlan.full_time_unit === 'month' && createdPlan.full_time >= 5">miesięcy</span>
                  <span v-if="createdPlan.full_time_unit === 'month' && createdPlan.full_time == 1">miesiąc</span>
                  <span v-if="createdPlan.full_time_unit === 'week' && createdPlan.full_time < 5"> tygodnie</span>
                  <span v-if="createdPlan.full_time_unit === 'week' && createdPlan.full_time >= 5"> tygodni</span>
                </div>
              </div>
            </div>
            <div class="text-center top-heading-big mt-4 mb-3">
              książki w planie
            </div>
      <div class="slider-wrapper">
        <div class="growing-cover-slider">
         <TrainingPlanStopwatch :props="books" :planId="plan_id" @update="update"/>
         <router-link :to="{name: 'Authors', params: {id: bookData.autor_id}}"  class="text-dark">
          <div class="profile-book-name text-center mt-3">
            <div class="book-heading-small">{{bookData.autor}}</div>
          </div>
         </router-link>
          <router-link :to="{name: 'BooksDetails', params: {id: bookData.id}}" class="text-dark">
            <div class="book-heading-big text-center">
              {{bookData.tytul}}
            </div>
          </router-link>
          <div class="stars-wrapper mt-1" v-if="bookData.rating > 0">
            <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
            :border-width='borderWidth' :show-rating="false" :read-only="true"
            :rounded-corners='rounded' :rating="bookData.rating" >
            </star-rating>
          </div>
          <div class="rating-size text-center mt-1" v-if="bookData.rating > 0">Ocena: {{parseFloat(bookData.rating, 10)}}</div>
        </div>
        <div class="book-heading-medium mt-3 text-center" v-if="(bookData.liczba_stron - bookData.przeczytane_strony) > 0">
            pozostało stron <br> <span>{{bookData.liczba_stron - bookData.przeczytane_strony}}</span>
        </div>
        <div class="book-heading-medium mt-3 text-center" v-else>
           książka przeczytana
        </div>
      </div>
            <hr class="section-spacer section-spacer-black">
              <div class="text-center top-heading-big mt-4 mb-3">
                kalendarz sesji czytania
              </div>
              <div class="text-center top-heading-small mb-3">
                Czytałeś poza trenerem? Klinkij w niewypełnioną sesję i zaktualizuj postęp
              </div>
            <div>
              <v-calendar
                is-expanded
                :attributes="attr"
                :max-date="new Date(maxSessionDate)"
                :min-date="new Date(minSessionDate)"
                @dayclick='dayClicked'
                :disabled-dates='[
                  {
                    start: new Date(tommorow),
                    end: new Date(maxSessionDate)
                  }
                ]'
                mode="range"
              />
            </div>
            </div>
            <div class="status-icon-row-summary row mt-4">
              <div class="col-4 pr-0">
                <div class="d-inline-block text-left">
                <div class="icon-big mb-0">
                    <i class="far fa-calendar-alt"></i>
                </div>
                <br> zrealizowane sesje <br> <span>{{finishedSessionsAmount}}</span>
                </div>
              </div>
              <div class="col-4 pl-0 pr-0 text-center">
                <div class="d-inline-block text-left">
                  <div class="icon-big mb-0">
                    <i class="fas fa-book"></i>
                  </div>
                  <br>
                  <!-- do ukończenia sesji brakuje <br> {{createdPlan.books_total - createdPlan.books_readed}} książka -->
                  <span>do ukończenia planu brakuje <br> {{this.sessionsAmount}} sesji</span>
                </div>
              </div>
              <div class="col-4 pl-0 text-right">
                <div class="d-inline-block text-left">
                <div class="icon-big mb-0">
                  <i class="far fa-clock"></i>
                </div>
                  <br>
                  plan ukończony <br/> w {{to_go}}%
                </div>
              </div>
            </div>
            <hr class="section-spacer section-spacer-black">
          <div class="text-center book-heading-big">
           podsumowanie
          </div>
            <div class="text-center top-heading-medium mt-4 mb-3">
              średnia dzienna liczba stron do przeczytania
            </div>
            <div class="book-heading-big text-center">
             {{createdPlan.pages_daily}}
            </div>
            <hr class="section-spacer section-spacer-black">
            <div class="text-center top-heading-medium mt-4 mb-3">
              średni dzienny czas czytania
            </div>
            <div class="book-heading-big text-center">
              {{createdPlan.minuts_daily}} minut
            </div>
            <hr class="section-spacer section-spacer-black">
            <div class="text-center top-heading-medium mt-4 mb-3">
              liczba książek w planie
            </div>
            <div class="book-heading-big text-center">
              {{createdPlan.books_total}}
            </div>
          </div>
        </div>
        <div v-else><Loader/></div>
      </div>
    </div>
</template>
<script>
import EventBus from '../eventBus'
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import ModalSlot from '../components/ModalSlot'
import Loader from '../components/Loader'
import TrainingPlanStopwatch from '../components/TrainingPlanStopwatch'
import StarRating from 'vue-star-rating'
export default {
  name: 'TrainingPlan',
  components: {
    TopMenuSubheadline,
    ModalSlot,
    Loader,
    TrainingPlanStopwatch,
    StarRating
  },
  data () {
    return {
      isPopupShown: false,
      popupSuccess: false,
      sessionsAmount: 0,
      finishedSessionsAmount: 0,
      minSessionDate: 0,
      maxSessionDate: 0,
      today: 0,
      tommorow: 0,
      to_go: 0,
      sessionsDone: [],
      isLoaded: false,
      isLoaded2: false,
      title: 'Plan czytelniczy',
      createdPlan: [],
      plan_id: 0,
      plansAmount: 0,
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18,
      bookData: { autor: '', tytul: '', autor_id: 0, id: 0, rating: 0, liczba_stron: 0, przeczytane_strony: 0 },
      attr: [
        {
          key: 'today',
          dot: 'indigo',
          highlight: {
            class: '',
            contentClass: ''
          },
          dates: new Date()
        }
      ],
      progress: [
        // {
        //   id: 1,
        //   date: new Date(),
        //   isCompleted: true,
        //   dots: false,
        //   class: 'bg-transparent circle-full',
        //   contentClass: 'text-dark'
        // }
      ]
    }
  },
  async created () {
    await this.getPlanData()
  },
  methods: {
    getPlanData () {
      EventBus.$on('training-plan-books', (payload) => {
        this.updateData(payload)
      })
      this.$https('/plans/current', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(({ data }) => {
          this.isLoaded2 = false
          this.plan_id = data.response.id
          this.createdPlan = data.response
          this.getPlanBooks()
          this.createdPlan.sessions.forEach(e => {
            if (e.done === '0') {
              this.sessionsAmount = this.sessionsAmount + 1
            } else {
              this.finishedSessionsAmount = this.finishedSessionsAmount + 1
            }
          })
          this.to_go = 100 - parseInt(this.createdPlan.to_go)
          const sessionArrLength = this.createdPlan.sessions.length - 1
          this.minSessionDate = this.createdPlan.sessions[0].from.slice(0, 10)
          this.maxSessionDate = this.createdPlan.sessions[sessionArrLength].from.slice(0, 10)
          this.minSessionDate = this.minSessionDate.replace(/-/gi, ', ')
          this.minSessionDate = this.minSessionDate.replace(/, 0/gi, ', ')
          this.maxSessionDate = this.maxSessionDate.replace(/-/gi, ', ')
          this.maxSessionDate = this.maxSessionDate.replace(/, 0/gi, ', ')
          this.sessionTime = data.id
          this.today = new Date()
          this.today = this.today.getFullYear() + '-' + (this.today.getMonth()) + '-' + this.today.getDate()
          this.today = this.today.replace(/-/gi, ', ')
          this.today = this.today.replace(/, 0/gi, ', ')
          const today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
          this.tommorow = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
          this.tommorow = this.tommorow.replace(/-/gi, ', ')
          this.tommorow = this.tommorow.replace(/, 0/gi, ', ')
          this.createdPlan.sessions.forEach(e => {
            if (e.done > 0) {
              const year = e.from.slice(0, 4)
              const month = parseInt(e.from.slice(5, 7)) - 1
              const day = e.from.slice(8, 10)
              this.attr.push({
                // id: e.id,
                // date: new Date(e.from.slice(0, 10).replace(/-/gi, ', ').replace(/, 0/gi, ', ')),
                // class: 'bg-dark circle',
                // dots: false,
                // contentClass: 'text-white'
                key: e.id,
                highlight: {
                  class: 'bg-secondary circle',
                  contentClass: 'text-white'
                },
                dates: new Date(year, month, day)
              })
            } else {
              const year = e.from.slice(0, 4)
              const month = parseInt(e.from.slice(5, 7)) - 1
              const day = e.from.slice(8, 10)
              this.attr.push({
                // id: e.id,
                // date: new Date(e.from.slice(0, 10).replace(/-/gi, ', ').replace(/, 0/gi, ', ')),
                // class: 'bg-transparent circle',
                // dots: 'red',
                // contentClass: 'text-danger'
                key: e.id,
                highlight: {
                  class: 'bg-transparent circle',
                  contentClass: 'text-danger'
                },
                dates: new Date(year, month, day)
              })
            }
          })
          this.isLoaded2 = true
        })
        .catch(error => {
          console.log('', error)
          this.trainingPlanTitle = ''
          this.$router.replace('/plan-treningowy/sukces')
        })
    },
    update () {
      this.$router.go(0)
    },
    getPlanBooks () {
      this.$https(`/plan/${this.plan_id}/books`, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(({ data }) => {
          this.books = data.response
          this.isLoaded = true
        })
    },
    deletePlan () {
      this.$https('/plan/' + this.plan_id + '/delete', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.closeModal()
        this.trainingPlanTitle = ''
        this.$router.push('/wybierz-plan')
      })
        .catch(error => {
          console.log('', error)
        })
    },
    openModal () {
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
    },
    dayClicked (day) {
      let today = new Date()
      let getday = today.getDate()
      getday = getday < 10 ? ('0' + getday) : getday
      today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + getday
      const minDay = this.createdPlan.sessions[0].from.slice(0, 10)
      if (day.id >= minDay && day.id <= today) {
        this.sessionTime = day.id
        this.$router.push({
          name: 'TrainingUpdateProgress',
          params: { id: this.plan_id, date: day.id }
        })
      }
    },
    updateData (payload) {
      this.bookData = payload
    }
  },
  computed: {
    sessionTime: {
      get () {
        return this.$store.state.sessionTime
      },
      set (value) {
        this.$store.commit('updateSessionTime', value)
      }
    },
    trainingPlanTitle: {
      set (value) {
        this.$store.commit('updateTrainingPlanTitle', value)
      },
      get () {
        return this.$store.state.trainingPlanTitle
      }
    }
    // attr () {
    //   return this.progress.map(t => ({
    //     key: `progress.${t.id}`,
    //     highlight: {
    //       class: t.class,
    //       contentClass: t.contentClass
    //     },
    //     dot: t.dots,
    //     dates: t.date,
    //     customData: t
    //   }))
    // }
  },
  beforeDestroy () {
    EventBus.$off('training-plan-books', (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .test {
    border: 1px solid pink;
  }
  .circle-quarter {
    border-radius: 50%;
  }
  .stars-wrapper {
  display: flex;
  justify-content: center;
  }
  .trainings-links {
    list-style-type: none;
    display: flex;
    width: 70%;
    justify-content: space-around;
  }
  .links-heading {
    font-size: 13px;
    text-align: center;
  }
  .rating-size {
    font-size: 14px;
  }
</style>
